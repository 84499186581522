import { ref } from '@vue/composition-api'
import axios from '@axios'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGradeMappings() {
  // Use toast
  const toast = useToast()

  const isLoading = ref(false)

  const fetchGradeMappingsList = queryParam => axios
    .get('/auth/academics/grade-mappings', {
      params: queryParam,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Grade mappings',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateGradeMappings = queryParams => axios
    .post('auth/academics/grade-mappings-bulk-save', queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Grade mappings',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteGradeMapping = id => axios
    .delete(`/auth/academics/grade-mappings/${id}`)
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Grade mapping',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    isLoading,
    fetchGradeMappingsList,
    updateGradeMappings,
    deleteGradeMapping,
  }
}
