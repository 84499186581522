<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-card
        no-body
      >
        <validation-observer
          ref="gradeMappingRules"
          tag="form"
        >
          <draggable
            v-model="gradeMappingsList"
            tag="ul"
            handle=".handle-drag"
            class="list-group list-group-flush"
          >
            <b-list-group-item
              v-for="(item, index) in gradeMappingsList"
              :key="index"
            >
              <b-row>
                <!-- <b-col
                  cols="1"
                  class="align-self-center"
                >
                  <img
                    src="@/assets/images/icons/more-vertical-v2.svg"
                    class="handle-drag cursor-move"
                  >
                </b-col> -->
                <b-col cols="4">
                  <div class="d-flex">
                    <img
                      src="@/assets/images/icons/more-vertical-v2.svg"
                      class="handle-drag cursor-move mr-1"
                    >
                    <b-form-group
                      label="From"
                      class="w-100"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="`From`"
                        rules="required"
                        :vid="`From ${index}`"
                      >
                        <b-form-input
                          v-model="item.from"
                          type="number"
                          :class="{'is-invalid': errors.length > 0 }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                </b-col>

                <b-col cols="4">
                  <b-form-group
                    label="To"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`To`"
                      rules="required"
                      :vid="`To ${index}`"
                    >
                      <b-form-input
                        v-model="item.to"
                        type="number"
                        :class="{'is-invalid': errors.length > 0 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Value"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Value`"
                      rules="required"
                      :vid="`Value ${index}`"
                    >
                      <b-form-input
                        v-model="item.value"
                        :class="{'is-invalid': errors.length > 0 }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
          </draggable>
          <b-list-group-item>
            <b-row
              align-h="center"
              class="my-1"
            >
              <b-button
                variant="outline-primary"
                @click="addNewMapping"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="14"
                  class="mr-1"
                />
                Add Mapping
              </b-button>
            </b-row>
          </b-list-group-item>

        </validation-observer>

        <div
          class="d-flex justify-content-end my-1"
        >
          <b-button
            variant="outline-primary"
            class="mr-2"
            @click="prepareGradeMappingsList"
          >
            Reset
          </b-button>
          <b-button
            variant="primary"
            class="mr-2"
            @click="saveGradeMappings"
          >
            Save Changes
          </b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BListGroupItem,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import useGradeMappings from './useGradeMappings'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'GradeMapping',
  components: {
    ValidationObserver,
    ValidationProvider,

    BOverlay,
    BCard,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BListGroupItem,

    draggable,
  },
  data() {
    return {
      gradeMappingsList: [],
      required,
      permissionSubjects,
    }
  },
  setup() {
    const {
      isLoading,
      fetchGradeMappingsList,
      updateGradeMappings,
      deleteGradeMapping,
    } = useGradeMappings()

    return {
      isLoading,
      fetchGradeMappingsList,
      updateGradeMappings,
      deleteGradeMapping,
    }
  },
  async created() {
    await this.prepareGradeMappingsList()
  },
  methods: {
    async prepareGradeMappingsList() {
      try {
        this.isLoading = true
        this.gradeMappingsList = await this.fetchGradeMappingsList()
      } finally {
        this.isLoading = false
      }
    },
    addNewMapping() {
      this.gradeMappingsList.push({
        from: '',
        to: '',
        value: '',
      })
    },
    async saveGradeMappings() {
      return new Promise(() => {
        this.$refs.gradeMappingRules.validate().then(success => {
          if (success) {
            this.handleUpdateGradeMappings()
          }
        })
      })
    },
    async handleUpdateGradeMappings() {
      try {
        this.isLoading = true
        await this.updateGradeMappings(this.gradeMappingsList)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style>

</style>
